import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";

import brainstorming from "../images/palmomedia-brainstorming.jpg";

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Das sind WIR- die palmomedia" />
      <h1>Über uns</h1>
      <p>
        Die palmomedia versteht sich als Dienstleister für IT Services. Wir
        beraten und liefern. Wir helfen da wo andere die Hände in die Luft
        strecken und wir scheuen uns nicht zuzugeben wenn wir etwas nicht
        können.
      </p>{" "}
      <h2>Wir sind die Möglichmacher!</h2>
      <p>
        <i>Ist ihr Problem lösbar, dann lösen wir es.</i>
      </p>
      <img src={brainstorming} alt="webseiten" className="images" />
      <h2>Unser Leistungsangebot</h2>
      <p>
        <i>Never touch a running System!?!</i>
      </p>
      <p>
        Diese und weitere Halbweisheiten suchen Sie bei uns vergeblich. Wir
        verstehen Sie und wissen wie wichtig auch in die Jahre gekommene
        Software für ein Unternehmen sein kann. Sprechen Sie uns an, wir machen
        es möglich!
      </p>
      <p>
        Im Zweifel kommen wir vorbei und helfen beim Entwirren. Wir verstehen
        uns als Team dort anzupacken, wo andere aufgeben.
      </p>
      <p>
        Sie haben einen Server, welcher in die Jahre gekommen ist?
        <br />
        Wir bringen das System in die Cloud!
      </p>
      <p>
        Sie haben eine Softwarekomponente dessen Code keiner mehr durchblickt?
        Wir refactoren und stabilisieren den Code in Punkto Sicherheit,
        Wartbarkeit und Betrieb.
      </p>
      <p>
        Sie brauchen einfach nur eine Webseite? Auch gar kein Problem. Wir
        schalten Ihnen direkt ein CMS (Content-Management-System) ihrer Wahl
        online. Kaufen Ihre Domain. Erstellen ein Theme nach Ihren Wünschen und
        kümmern uns, wenn Sie mögen sogar um die Inhalte auf der Webseite und in
        den Sozialen Medien.
      </p>
      <h2>Das Versprechen</h2>
      <p>
        Wir stellen uns strikt agil auf. Das heisst für uns, jeder packt da an,
        wo er benötigt wird und wir ziehen alle am gleichen Strang in die
        gleiche Richtung. Dazu organisieren wir uns flach und planen ein Projekt
        miteinander. Kunde (Stakeholder) und Auftragnehmer (Team) kollaborieren
        - das funktioniert nicht zuletzt dank unserer eingesetzten Tools.
      </p>
      <p>
        Sie fragen sich was mit Sicherheit und Qualitätsmanagement ist? Für uns
        geht das in DevOps mit auf. Moderne Terminologie spricht schon von
        SecDevOps, also Security DevOps und der ähnlichen. Wichtiger als der
        Begriff ist für uns die Qualität, die damit einhergeht. Wir betrachten
        nicht nur den User-Aspekt einer Software, sondern auch die
        Betreibbarkeit, die Wartbarkeit und die Sicherheit.
      </p>
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
